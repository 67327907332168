import React, { useEffect, useState, useRef } from "react";
import { Cart } from '@ctt/ui';
import { listenEvent } from '@ctt/utils';

export default function Root(props) {

  const [total, setTotal] = useState(0); 

  useEffect(() => {
    listenEvent('@ctt/process/add-cart', ({detail}) => {
      setTotal((prevTotal) => {
        const sumTotal = prevTotal + detail.item;
        return sumTotal;
      });
    })
  }, [])

  return (
    <>
      <section>{props.name} is mounted!</section>
      <Cart item={total}/>
    </>
  );
}
